textarea {
    background: #fff;
    color: rgb(58 34 102);
    line-height: 1.2em;
    border: 1px solid rgba(121, 68, 217, 0.6);
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 1px 0 rgba(255, 255, 255, 0.2);
    margin: 10px auto;
    padding: 7px;
    width: -webkit-fill-available;
    height: 100px;
    outline: none;
    font-size: 16px;
}

textarea:focus {
    background: #ebf8fd;
    text-indent: 0;
    z-index: 1;
    color: rgb(58 34 102);
    opacity: 0.8;
    box-shadow: 0 0 5px rgba(121, 68, 217, 0.5);
    /*border-color: #ccc;*/
    border: 1px solid rgba(121, 68, 217, 0.6);
}