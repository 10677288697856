:root {
    --color: rgb(249, 231, 255);
}
.languages {
}

img {
    width: 45px;
    height: 30px;
    cursor: pointer;
    z-index: 1000;
}

ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 6px;
    background: var(--color);
    padding: 10px;
    border: 2px solid rgba(121, 68, 217, 0.6);
    line-height: 0;
    max-width: 480px;
    margin: 20px auto;
    /*gap: 2px;*/
}

.beforeTop {
    position: absolute;
    content: "";
    display: block;
    width: 4.3px;
    height: 3px;
    background: rgb(172 132 233);
    top: 9px;
    left: 7.8px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.afterTop {
    position: absolute;
    content: "";
    display: block;
    width: 4.3px;
    height: 3px;
    background: rgb(172 132 233);
    top: 9px;
    left: 67.8px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.beforeBottom {
    position: absolute;
    content: "";
    display: block;
    width: 4.3px;
    height: 3px;
    background: rgb(172 132 233);
    /* box-shadow: 10px 10px 2px rgba(121, 68, 217); */
    top: 62px;
    left: 67.8px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.afterBottom {
    position: absolute;
    content: "";
    display: block;
    width: 4.3px;
    height: 3px;
    background: rgb(172 132 233);
    /* box-shadow: 10px 10px 2px rgba(121, 68, 217); */
    top: 62px;
    left: 7.8px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.indicator {
    position: absolute;
    top: 8px;
    width: 80px;
    height: 74px;
    background: var(--color);
    border-radius: 50%;
    z-index: 0 ;
    border: 2px solid;
    border-left-color: var(--color);
    border-right-color: var(--color);
    border-top-color: rgba(121, 68, 217, 0.6);
    border-bottom-color: rgba(121, 68, 217, 0.6);
}

li {
    transition: transform .2s linear;
    z-index: 10;
    opacity: 0.8;
}

.active {
    transform: scale(125%);
    opacity: 1;
}

:root {
    --size-l: -0.77;
}


li:nth-child(1).active ~ .indicator {
    transition: transform 0.3s;
    transform: translate(-192px);
}
li:nth-child(2).active ~ .indicator {
    transition: transform 0.3s;
    transform: translate(-96px);
}
li:nth-child(3).active ~ .indicator {
    transition: transform 0.3s;
    transform: translate(0);
}
li:nth-child(4).active ~ .indicator {
    transition: transform 0.3s;
    transform: translate(96px);
}
li:nth-child(5).active ~ .indicator {
    transition: transform 0.3s;
    transform: translate(192px);
}

@media (max-width: 520px) {
    li:nth-child(1).active ~ .indicator {
        transition: transform 0.3s;
        transform: translate(-36vw);
    }
    li:nth-child(2).active ~ .indicator {
        transition: transform 0.3s;
        transform: translate(-18vw);
    }
    li:nth-child(3).active ~ .indicator {
        transition: transform 0.3s;
        transform: translate(0);
    }
    li:nth-child(4).active ~ .indicator {
        transition: transform 0.3s;
        transform: translate(18vw);
    }
    li:nth-child(5).active ~ .indicator {
        transition: transform 0.3s;
        transform: translate(36vw);
    }
}

@media (max-width: 370px) {
    li:nth-child(1).active ~ .indicator {
        transition: transform 0.3s;
        transform: translate(-35.5vw);
    }
    li:nth-child(5).active ~ .indicator {
        transition: transform 0.3s;
        transform: translate(35.5vw);
    }
}