.blocker {
display: none;
}

input[type="text"], input[type="tel"] {
    background: #fff;
    border: 1px solid rgba(121, 68, 217, 0.6);
    color: rgb(58 34 102);
    font-size: 16px;
    line-height: 1.2em;
    margin-bottom: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 1px 0 rgba(255, 255, 255, 0.2);
    padding: 8px 6px;
    height: 22px;
    width: 220px;
    font-family: SF;
}

input[type="text"]:focus {
    background: #f5fcfe;
    text-indent: 0;
    z-index: 1;
    color: rgb(58 34 102);
    transition-duration: 600ms;
    transition-property: width, background;
    transition-timing-function: ease;
    width: 85%;
    border: 1px solid rgba(121, 68, 217);
    /*box-shadow: 0 0 5px rgba(4, 129, 177, 0.5);*/
    /*opacity: 0.8;*/
    outline: none;

}