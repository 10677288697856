.content {
    white-space: pre-line; /* Трансформирует пробелы, как мягкий чемодан */
    position: relative;
    /*padding: 20px;*/
    /*width: 950px;*/
    max-width: 500px;
    /*height: 100%;*/
    margin: 20px auto;
    /*font-family: Verdana, sans-serif;*/
    font-family: SF, sans-serif;
}

fieldset {
    border-radius: 6px;
    /*background: rgb(113 24 121 / 0.04);*/
    background: rgb(251 224 253 / 0.5);
    padding: 17px;
    border-color: rgba(121, 68, 217, 0.6);
    opacity: 0.96;
}

legend {
    color: rgb(121, 68, 217);
    font-size: 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(121, 68, 217, 0.5);
    padding: 5px 10px;
    text-transform: uppercase;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
}

input[type="submit"] {
    background: rgb(121, 68, 217);
    border: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    color: #eee;
    cursor: pointer;
    font-size: 15px;
    margin: 5px 0;
    padding: 8px 25px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

input[type="reset"] {
    background: #e1eaf5;
    border: none;
    text-transform: uppercase;
    color: #837e7e;
    cursor: pointer;
    font-size: 15px;
    margin: 5px 5px;
    padding: 8px 20px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}