.labels {
    font-size: 16px;
    color: rgb(58 34 102);
    padding: 10px;
    background: #fff;
    border: 1px solid rgba(121, 68, 217, 0.6);
    /*line-height: 1.2em;*/
    margin-bottom: 15px;
    border-radius: 6px;
    /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 1px 0 rgba(255, 255, 255, 0.2);*/
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.4) inset, 0 1px 0 rgba(255, 255, 255, 0.2);
}

.labelHeader {
    text-align: center;
    line-height: 25px;
    text-shadow: rgb(149, 90, 253) 0px 0px 20px;
    /*animation: changeLang 1s ease infinite;*/
    transition: opacity 0.3s;
}
@keyframes changeLang {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.labelElements {
    margin: 20px auto;
    transition: opacity 0.3s;

}

.inputElements {
    accent-color: rgb(121, 68, 217);
}

/*.check_Boxes {*/
/*    margin: 20px auto;*/
/*}*/

.alert {
    border: 2px solid red;
}