.modal {
    /*display: none;*/
    z-index: 1000;
    position: absolute;
    left: 210px;
    /*top:800px;*/
    width: 43vw;
    height: 10px;
    min-width: 150px;
    max-width: 240px;
    /*display: block;*/
    visibility: visible;
    margin-right: 0px;

}

.childModal {
    background-color: rgb(254 242 255);
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid rgb(188 0 255);
    box-shadow: rgba(136, 136, 136, 0.93) 0px 0px 20px 0px;
    display: block;
    font-size: 13px;

}

.caret {
    position: absolute;
    z-index: 1000;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    top: 15px;
    border-right: 11px solid rgb(188 0 255);
    left: -10px;
}